/**
 * Returns name index url
 * @param {object} opts
 * @param {string} opts.index
 * @param {*} opts.defaultValue
 * @returns {null|string}
 */
const getNameIndexUrl = (opts = {}) => {
  if (!opts || !opts.index) {
    return opts?.defaultValue ?? '';
  }
  const letter = opts.index[0].toLowerCase();
  const tier2 = opts.index.slice(1, 4);
  const tier3 = opts.index.slice(4);
  return `/names/${letter}/${tier2}/${tier3}/`;
};

export default getNameIndexUrl;
