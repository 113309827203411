/**
 * Converts a one dimensional array into a multidimensional array
 *
 * @param {object} opts
 * @param {array} opts.array
 * @param {number} opts.size
 * @return {[]}
 */
const splitArray = (opts = {}) => {
  if (!opts.array) {
    return [];
  }

  const columnSize = opts.size || 4;
  let columns = [];
  let mid = Math.ceil(opts.array.length / columnSize);

  for (let col = 0; col < columnSize; col++) {
    columns.push(opts.array.slice(col * mid, col * mid + mid));
  }

  return columns;
};

export default splitArray;
