<template>
  <table class="wl-names-table">
    <thead class="wl-names-table__head">
      <tr class="wl-names-table__row">
        <th
          class="wl-names-table__header wl-names-table__header--rank"
          v-text="rank"
        ></th>
        <th
          class="wl-names-table__header wl-names-table__header--name"
          v-text="name"
        ></th>
        <th
          class="wl-names-table__header wl-names-table__header--count"
          v-for="item in renderIf(count)"
          :key="item"
          v-text="count"
        ></th>
      </tr>
    </thead>
    <tbody class="wl-names-table__body">
      <slot />
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'wl-names-table',
  methods: {
    /**
     * If statement vue hack to prevent <!-- --> comment
     * @param condition
     * @returns {[]}
     */
    renderIf(condition) {
      return condition ? [Math.random()] : [];
    }
  },
  props: {
    // Display rank number
    rank: {
      type: String,
      required: true
    },
    // Displays first and last name
    name: {
      type: String,
      required: true
    },
    // Displays the count number of a result
    count: {
      type: String,
      required: false
    }
  }
};
</script>
