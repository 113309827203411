<template>
  <tr class="wl-names-row">
    <td class="wl-names-row__data wl-names-row__data--rank" v-text="rank"></td>
    <td class="wl-names-row__data wl-names-row__data--name">
      <a :href="url" class="wl-names-row__link" v-text="name" />
    </td>
    <td
      class="wl-names-row__data wl-names-row__data--count"
      v-for="item in renderIf(count)"
      :key="item"
      v-text="count"
    ></td>
  </tr>
</template>

<script>
export default {
  name: 'wl-names-row',
  methods: {
    /**
     * If statement vue hack to prevent <!-- --> comment
     * @param condition
     * @returns {[]}
     */
    renderIf(condition) {
      return condition ? [Math.random()] : [];
    }
  },
  props: {
    // Display rank number
    rank: {
      type: String,
      required: true
    },
    // Displays first and last name
    name: {
      type: String,
      required: true
    },
    // Links name result
    url: {
      type: String,
      required: true
    },
    // Displays the count number of a result
    count: {
      type: String,
      required: false
    }
  }
};
</script>
