const initialResultsBreadcrumb = {
  href: '/',
  text: 'People Directory'
};

/**
 * Return a single breadcrumb
 *
 * @param {string} text
 * @param {boolean} disabled
 * @param {string} href
 * @param {string} emoji
 * @param {string} schemaName
 * @returns {{disabled: *, text: *, href: *, emoji: *, schemaName: *}}
 */
const getBreadcrumb = function(opts = {}) {
  return opts;
};

const breadcrumbsHelper = {
  /**
   * Returns formatted page number if page number exists
   *
   * @param {string|number|null} page
   * @returns {string}
   */
  getFormattedPageNumber: page => {
    return page ? `/${page}/` : '';
  },
  /**
   * Return a formatted results breadcrumb list
   *
   * @param {string} formattedFirstName
   * @param {string} formattedLastName
   * @param {string} rawFirstName
   * @param {string} rawLastName
   * @param {string} pageNumber
   * @param {string|number|null} indexResponse
   * @param {string|null} rawState
   * @param {string|null} state
   * @param {string|null} formattedCity
   * @param {string|null} city
   * @param {string|null} profilePath
   * @returns {[{href: string, text: string}]}
   */
  setResultsCrumbs: (
    formattedFirstName,
    formattedLastName,
    rawFirstName,
    rawLastName,
    pageNumber,
    indexResponse,
    rawState,
    state,
    formattedCity,
    city,
    profilePath
  ) => {
    const hasState = state;
    const hasCity = city;
    const formattedName = `${formattedFirstName} ${formattedLastName}`;
    const letter = formattedLastName.slice(0, 1);
    let nameLink = `/people/${rawFirstName}-${rawLastName}/`;

    // Start at Home Page
    const crumbs = [initialResultsBreadcrumb];

    // Add Search Reference if there are no names and return as no other crumbs necessary
    if (!formattedLastName) {
      crumbs.push(
        getBreadcrumb({
          text: 'Search',
          disabled: false,
          href: `/search/i/${letter}/${indexResponse}/`
        })
      );

      return crumbs;
    }

    if (letter) {
      // Add People Link
      crumbs.push(
        getBreadcrumb({
          text: 'People',
          disabled: false,
          href: `/names/${letter.toLowerCase()}/`,
          emoji: '&#128209;'
        })
      );

      // Add Letter Link
      crumbs.push(
        getBreadcrumb({
          text: `(${letter})`,
          disabled: false,
          href: `/names-by-letter/${letter.toLowerCase()}/`,
          emoji: '&#128213;'
        })
      );
    }

    let disabledNameLink = hasState || hasCity ? false : !pageNumber;

    // Add full name link
    crumbs.push(
      getBreadcrumb({
        text: formattedName,
        disabled: disabledNameLink,
        href: nameLink,
        emoji: '&#128221;'
      })
    );

    let lastLink = nameLink;

    if (hasState) {
      let fullStateLink = `${nameLink}${rawState}/`;

      lastLink = fullStateLink;
      crumbs.push(
        getBreadcrumb({
          text: state,
          disabled: (!hasCity && !pageNumber) || !!profilePath,
          href: fullStateLink,
          emoji: '&#x1F6E9;'
        })
      );
    }

    if (hasCity) {
      let fullCityLink = `${nameLink}${rawState}/${city}/`;
      lastLink = fullCityLink;
      crumbs.push(
        getBreadcrumb({
          text: formattedCity,
          disabled: !pageNumber,
          href: fullCityLink,
          emoji: '&#x1F307;'
        })
      );
    }

    if (profilePath) {
      crumbs.push(
        getBreadcrumb({
          text: 'Profile',
          disabled: true,
          href: profilePath
        })
      );
    }

    if (pageNumber) {
      crumbs.push(
        getBreadcrumb({
          text: `(Page ${pageNumber})`,
          disabled: true,
          href: lastLink
        })
      );
    }

    return crumbs;
  },
  /**
   * Returns breadcrumbs for the optout pages
   *
   * @param {object} breadcrumbsCMS
   * @param {boolean|null} includeStepOne
   * @param {boolean|null} includeStepTwo
   * @param {boolean|null} includeStepThree
   * @returns {any[]|*[]}
   */
  setOptOutBreadcrumbs(
    breadcrumbsCMS,
    includeStepOne,
    includeStepTwo,
    includeStepThree
  ) {
    if (!breadcrumbsCMS) {
      return [];
    }

    const crumbs = [breadcrumbsCMS.initial];

    if (includeStepOne) {
      const stepOneCrumb = breadcrumbsCMS.step_one;
      stepOneCrumb.disabled = !includeStepTwo;
      crumbs.push(stepOneCrumb);
    }

    if (includeStepTwo) {
      const stepTwoCrumb = breadcrumbsCMS.step_two;
      stepTwoCrumb.disabled = !includeStepThree;
      crumbs.push(stepTwoCrumb);
    }

    if (includeStepThree) {
      crumbs.push(breadcrumbsCMS.step_three);
    }

    return crumbs;
  },
  /**
   * @param {string} date
   * @param {string} path
   */
  setTrendingNamesBreadcrumb: (date, path) => {
    const trendingNamesPath = '/popular-names/';
    const crumbs = [initialResultsBreadcrumb];

    crumbs.push(
      getBreadcrumb({
        text: 'Popular Names',
        disabled: !date,
        href: trendingNamesPath
      })
    );

    if (date) {
      const d = new Date(date);
      const month = d.toLocaleString('default', { month: 'long' });
      const day = d.getDate();
      const year = d.getFullYear();
      const formattedDate = `${month} ${day}, ${year}`;

      crumbs.push(
        getBreadcrumb({ text: formattedDate, disabled: true, href: path })
      );
    }

    return crumbs;
  },
  /** Return a formatted results breadcrumb list for top full names pages
   * @param {string|null} state
   * @returns {[{href: string, text: string}]}
   **/
  getTopFullNamesBreadcrumbs: state => {
    const rootPath = '/top-rated-full-names/';
    const rootCrumb = getBreadcrumb({
      text: 'Top Rated Full Names',
      disabled: !state,
      href: rootPath
    });
    const crumbs = [initialResultsBreadcrumb, rootCrumb];
    if (state) {
      crumbs.push(
        getBreadcrumb({
          text: state,
          disabled: true,
          href: `${rootPath}${state}`
        })
      );
    }

    return crumbs;
  },
  /**
   * Return a formatted results breadcrumb list for top last names pages
   * @param {boolean} includeAlphabet
   * @param {string|null} letter
   * @param {boolean} includeStates
   * @param {string|null} state
   * @returns {[{href: string, text: string}]}
   **/
  getTopLastNamesBreadcrumbs: (
    includeAlphabet,
    letter,
    includeStates,
    state
  ) => {
    const rootPath = '/top-rated-last-names/';
    const rootDisabled =
      !includeAlphabet && !letter && !includeStates && !state;
    const rootCrumb = getBreadcrumb({
      text: 'Top Rated Last Names',
      disabled: rootDisabled,
      href: rootPath
    });
    const crumbs = [initialResultsBreadcrumb, rootCrumb];
    if (includeAlphabet) {
      crumbs.push(
        getBreadcrumb({
          text: 'By Letter',
          disabled: !letter,
          href: `${rootPath}letter/`
        })
      );
    }
    if (letter) {
      crumbs.push(
        getBreadcrumb({
          text: letter,
          disabled: true,
          href: `${rootPath}letter/${letter}/`
        })
      );
    }
    if (includeStates) {
      crumbs.push(
        getBreadcrumb({
          text: 'By State',
          disabled: !state,
          href: `${rootPath}state/`
        })
      );
    }
    if (state) {
      crumbs.push(
        getBreadcrumb({
          text: state,
          disabled: true,
          href: `${rootPath}state/${state}`
        })
      );
    }
    return crumbs;
  },
  setBreadcrumb: (opts = {}) => {
    let crumbs = [initialResultsBreadcrumb];

    if (opts.page === 'phone') {
      crumbs.push({
        href: '/reverse-phone-lookup/',
        text: 'Reverse Phone Lookup'
      });
    }

    if (opts.page === 'address') {
      crumbs.push({
        href: '/reverse-address-lookup/',
        text: 'Reverse Address Lookup'
      });
    }

    crumbs.push(
      getBreadcrumb({ text: opts.text, disabled: false, href: opts.path })
    );

    if (opts.pageNumber) {
      crumbs.push(
        getBreadcrumb({
          text: `(Page ${opts.pageNumber})`,
          disabled: false,
          href: `${opts.path}/`
        })
      );
    }

    // Last breadcrumb should be disabled
    crumbs[crumbs.length - 1].disabled = true;

    return crumbs;
  }
};

export default breadcrumbsHelper;
