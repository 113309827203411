import setTitleCase from './setTitleCase';

/**
 * Display person's name on the table
 * If they have a state, display it next to the name in parenthesis
 *
 * @param {object} opts
 * @param {object} opts.person
 * @returns {string}
 */
const displayNameOnTable = (opts = {}) => {
  const { firstname, lastname, state } = opts.person;
  const formattedFirstName = setTitleCase({ text: firstname });
  const formattedLastName = setTitleCase({ text: lastname });
  let name = '';

  if (firstname) {
    name += formattedFirstName + ' ';
  }

  if (lastname) {
    name += formattedLastName;
  }

  if (state) {
    name += ` (${state})`;
  }

  return name;
};

export default displayNameOnTable;
